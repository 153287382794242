$sidenav-width: 20rem;

.slidenav-toggle {
    position: fixed;
    right: 10px;
    top: 10px;
}

.slidenav {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999999;
    display: none;

    &-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background: rgba($brand-primary, 0.45);
        cursor: pointer;
    }

    &-content {
        transform: translateX(-$sidenav-width);
        transition: transform 0.2s ease;
        padding: 30px;
        left: 0;
        width: $sidenav-width;
        height: 100%;
        position: absolute;
        background: $brand-primary;
        z-index: 2;
        @include scrollable;

        &-active {
            transform: translateX(0);
        }

        &-close {
            float: right;
            padding: 5px;
            background: transparent;
            border: none;
        }

        &-heading {
            color: #fff;
            margin: 0 0 1rem 0;
            font-size: 1.8rem;
        }

        &-item {
            display: block;
            margin: 1rem 0;
        }
    }

    &-active {
        display: block;
    }

    a {
        color: #FFF;
    }
}
