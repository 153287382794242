.sitefooter {
    background: $brand-primary;
    color: #FFF;
    padding: 40px 0;

    a {
        color: #FFF;
        text-decoration: underline;
    }

    &-nav {
        color: #FFF;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &-copyright {
        padding: 20px 0;
    }
}
