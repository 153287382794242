.footer-dark {
  padding:50px;
  color:#f0f9ff;
  background-color:$brand-primary;
}

.footer-dark h6 {
  opacity:1;
  font-size:0.9rem;
  padding-top:20px;
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:1rem;
  margin-bottom:0;
}

.footer-dark ul a {
  color:inherit;
  opacity:0.7;
}

.footer-dark ul a:hover {
  opacity:1;
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }
}

.footer-about {
  opacity:0.7;
  padding:10px 60px 0 0;
  font-size:0.9rem;
}

.copyright {
  text-align:center;
  opacity:0.7;
  font-size:0.9rem;
}

.footer-contact {
  font-size:0.6rem;

}

.footer-form {
  width: 100%;
  margin: 0 0 10px;
  padding: 10px 8px;
  text-transform: none;
  font-family: Roboto,sans-serif;
  font-weight: 400;
  line-height: 10px;
  font-size: 12px;
  color: #FFF;
  font-style: normal;
  border-radius: 0;
  background: 0 0;
  border: 1px solid rgba(#FFF, 0.6);
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: border-color .3s ease-in-out;
  -webkit-transition: border-color .3s ease-in-out;
}

.footer-form-btn {
    display: inline-block;
    cursor: pointer;
    margin: 0 7px 10px 0;
    padding: 13px 18px 10px;
    border: 1px solid rgba(#FFF, 0.6);
    border-radius: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    text-shadow: none;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $font-family-base;
    font-weight: 600;
    line-height: 10px;
    font-size: 14px;
    color: #FFF;
    background: 0 0;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;

    &:hover, &:focus {
        background: $brand-inverse;
        color: #FFF;
        text-decoration: none;
    }
}
