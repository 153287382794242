.section {
    padding: 25px 0;

    &-dark {
        background: $brand-primary;
        color: #FFF;

        a {
            color: #FFF;
        }
    }
}
