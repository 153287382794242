.sitenav {
    background: #fff;
    box-shadow: 0px -18px 20px 10px #000;

    &-logo {
        margin: 15px 0 15px 0;
        display: block;
    }

    &-links {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: right;
        margin: 26px 0 0 0;
        font-size: 1.1rem;

        li {
            display: inline-block;
        }

        a {
            color: $brand-primary;
            padding: 1.2rem 1.5rem;
            padding-bottom: 15px;
            transition:.25s;
            @include border-radius(100px);

            &:hover, &:focus {
                background: $brand-inverse;
                color: #FFF;
                text-decoration: none;
            }
            &:hover {
                background: none;
                box-shadow:0px 0px 0px 1px $brand-inverse inset;
                color: $brand-primary;
                //border: 1px solid $brand-primary;
            }

            &.active {
                background: $brand-inverse;
                color: #FFF;

                &:hover {
                    text-decoration: none;
                    box-shadow: none;
                }
            }
        }

        .submenu {
            position: fixed;
            top: 75px;
            z-index: 999;
            width: 100vw;
            left: 0px;
            right: 0px;
            visibility: hidden;
            padding-bottom: 50px;
            opacity: 0;
            transition: visibility 0s, opacity 0.10s linear;

            a {
                box-shadow: none;
                color: #fff;
                border-radius: 0px;
                @include media-breakpoint-down(lg) {
                    zoom: 80%;
                }
            }
            a:hover {
                box-shadow: none;
                color: #fff;
                background-color: #54234b;
            }
            .d-block {
                height: 190px;
                @include media-breakpoint-down(lg) {
                    height: 200px;
                }
            }
        }

        .submenu.services:hover + .mnu-services {
            box-shadow:0px 0px 0px 1px $brand-inverse inset !important;
            color: $brand-primary;
        }

        .mnu-services:hover + .submenu.services, .submenu.services:hover {
            visibility: visible;
            opacity: 1;
        }

    }

    .submenu {
        @include media-breakpoint-down(lg) {
            zoom: 85%;
            width: 125vw;
            top: 92px;
        }
        @include media-breakpoint-up(xl) {
            zoom: 100%;
        }
    }

}
