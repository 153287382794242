h5 {
    line-height: 27px;
}
.content-home {
    margin: auto;
    text-align: center;
    margin-top: 50px;
}

.content-home  h2 {
      margin-bottom: 25px;
}

.content-home  p {
      font-size: 1.3rem;
      margin: 0;
}

.content-image1 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: url('/images/hero/droid-001.jpg') no-repeat 50%;
      background-size: cover;
      height: 700px;
}

.purple_line{
    display:block;
    border:none;
    color:white;
    height:1px;
    background:black;
    background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(#000), to(#2e0927));
}

.what-we-do {
    h2 {
        margin-top: -40px;
    }
    .row {
        padding-bottom: 20px;
    }
}


// IoT Services
.container.iot-services-header, .container.iot-development-header {

    @include media-breakpoint-down(md) {
        padding: 20px;
    }
    @include media-breakpoint-up(md) {
        padding: 40px;
    }
    .outline-buttons {
        padding-top: 20px;
    }
    @include media-breakpoint-up(md) {
        min-height: 580px;
    }
    @include media-breakpoint-between(xs,sm) {
        min-height: 500px;
    }
    @include media-breakpoint-down(sm) {
        .btn-outline {
            zoom: 90%;
        }
    }

    z-index: 10;
}
.container.iot-development-header {
    min-height: 150px;
    .icons-inline {
        @include media-breakpoint-between(md, lg) {
            zoom: 90%;
        }
        @include media-breakpoint-up(md) {
            padding: 0px;
        }
    }
}


.iot-services-selection-animation {

    background: #fcfcfc;
    background: linear-gradient(to bottom, #fcfcfc 0, #e8e8e8 100%);
    //min-width: 1310px;
    transition: all 8s;
    overflow: hidden;
    z-index: -1;

    .city-background-container {
        position: relative;
        bottom: 0px;

        // Smaller zoom on mobile devices...
        @include media-breakpoint-between(xs, sm) {
            zoom: 70%;
        }

    }

    .hero__img-background {
        bottom: 30px;
        left: 50%;
        margin-left: -400px;
        position: absolute;
        width: 800px;
    }

    .hero__img-foreground {
        bottom: -10px;
        left: calc(50% - 270px);
        position: absolute;
        height: 276px;
        width: 550px;
    }
    .hero__img-bus {
        bottom: 17px;
        position: absolute;
        left: -150px;
        width: 128px;

        animation-name: animateBus;
        animation-duration: 30000ms;
        animation-delay: 1500ms;
        animation-iteration-count: infinite;

    }

    .hero__img-taxi {
        bottom: 5px;
        position: absolute;
        right: -150px;
        width: 90px;

        animation-name: animateTaxi;
        animation-duration: 20000ms;
        animation-delay: 15000ms;
        animation-iteration-count: infinite;

    }

    .hero__img-cloud-1 {
        left: calc(50% - 450px);
        position: absolute;
        bottom: 220px;
        width: 71px;

        animation-name: animateCloudRL;
        animation-duration: 15000ms;
        animation-delay: 500ms;
        animation-iteration-count: infinite;

    }

    .hero__img-cloud-2 {
        left: calc(50% + 300px);
        position: absolute;
        bottom: 250px;
        width: 48px;

        animation-name: animateCloudLR;
        animation-duration: 20000ms;
        animation-delay: 1000ms;
        animation-iteration-count: infinite;

    }

    .hero__img-cloud-3 {
        left: calc(50% + 150px);
        position: absolute;
        bottom: 320px;
        width: 48px;

        animation-name: animateCloudRL;
        animation-duration: 17000ms;
        animation-delay: 200ms;
        animation-iteration-count: infinite;

    }
    .hero__img-cloud-4 {
        left: calc(50% - 120px);
        position: absolute;
        bottom: 280px;
        width: 37px;

        animation-name: animateCloudLR;
        animation-duration: 15000ms;
        animation-delay: 1500ms;
        animation-iteration-count: infinite;

    }

    .hero__img-balloon-red {
        left: calc(50% - 105px);
        position: absolute;
        bottom: 250px;
        width: 30px;

        animation-name: animateBalloon1;
        animation-duration: 15000ms;
        animation-delay: 500ms;
        animation-iteration-count: infinite;

    }
    .hero__img-balloon-yellow {
        left: calc(50% + 5px);
        position: absolute;
        bottom: 250px;
        width: 38px;

        animation-name: animateBalloon2;
        animation-duration: 10000ms;
        animation-delay: 500ms;
        animation-iteration-count: infinite;

    }

    @keyframes animateCloudRL
    {
        0%   { transform: translateX(0); }
        50%  { transform: translateX(20%); }
        100% { transform: translateX(0); }
    }
    @keyframes animateCloudLR
    {
        0%   { transform: translateX(0); }
        50%  { transform: translateX(-40%); }
        100% { transform: translateX(0); }
    }
    @keyframes animateBalloon1
    {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-60%); }
        100% { transform: translateY(0); }
    }
    @keyframes animateBalloon2
    {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-40%); }
        100% { transform: translateY(0); }
    }
    @keyframes animateBus
    {
        0%   { transform: translateX(0); }
        100% { transform: translateX(2000%); }
    }
    @keyframes animateTaxi
    {
        0%   { transform: translateX(0); }
        100% { transform: translateX(-2500%); }
    }

}

.container.iot-services {
    .white-col-boxes, .grey-col-boxes {
        .col {
            padding-top: 40px;
            padding-bottom: 10px;

            @include media-breakpoint-down(sm) {
                min-width: 250px;
            }

        }
    }

    .section-header-box {
        padding: 40px;
        padding-top: 0px;
    }

    .section-description {
        h4 {
            padding-bottom: 15px;
        }
        h5 {
            min-height: auto;
            padding-bottom: 10px;
        }
    }

    .icon {
        color: #09ABB7;
        text-align: center;
        padding-bottom: 20px;

        svg {
            height: 100px;
            fill: currentColor;
        }
    }
    h5 {
        text-align: center;
        font-size: 18px;
        min-height: 60px;
        line-height: 24px;
    }
}

.five-pillars {
    padding-bottom: 40px !important;
}

// Consulting Services Boxes
.section.offering-boxes {
    background-color: #f7f7f7;
    .solutions {
        div[class^="col-"] {

            @include media-breakpoint-up(lg) {
                zoom: 80%;
            }

            @include media-breakpoint-up(xl) {
                zoom: 80%;
            }

            padding: 0px;
            .content-container {
                background-color: #fff;
                margin: 10px;
                padding: 10px;
                transition:.5s;
                min-height: 360px;
            }
            .content {
                display: none;
                h4 {
                    padding: 10px;
                    padding-left: 20px;
                    color: #fff;
                    @include media-breakpoint-down(xs) {
                        zoom: 80%;
                    }
                }
                ul {
                    list-style: none;
                    padding-left: 0px;
                }
                li {
                    border-bottom: 1px solid #ddd;
                    margin: 15px;
                    margin-right: 20px;
                    margin-left: 20px;
                    opacity: 0;
                }
            }
            .overlay {
                padding: 20px;
                h4 {
                    margin-top: 60px;
                }
                svg {
                    margin-top: 20px;
                    height: 50px;
                }
                p {
                    padding-top: 20px;
                }
            }
        }
    }

    .solutions {
        div[class^="col-"]:hover, div[class^="col-"]:active {
            .content-container {
                background-color: $brand-primary;
                color: #fff;
            }
            .overlay {
                display: none;
            }
            .content {
                display: inherit;
                h4 {
                    animation: slideUp 0.13s linear;
                    position: absolute;
                }
            }
            ul {
                padding-top: 40px;
            }

            li {
                border-bottom: 1px solid #444;
                animation: fadeIn 0.5s linear;
                animation-fill-mode: both;
            }

            $total-items: 10;

            // Set delay per List Item
            @for $i from 1 through $total-items {
              li:nth-child(#{$i}) {
                animation-delay: .05s * $i;
              }
            }

            // Keyframe animation for list fading in...
            @-webkit-keyframes fadeIn {
              0% {
                opacity: 0;
                top: 100px;
              }
              75% {
                opacity: 0.5;
                top: 0px;
              }
              100% {
                opacity: 1;
              }
            }

            // Animation to slide h4 up when hover...
            @keyframes slideUp {
              0% {
                top: 120px;
              }
              100% {
                top: 10px;
              }
            }
        }
    }
}

// General Styles
.section.grey-bg {
    background-color: #f7f7f7;
}
.section.purple-bg {
    background-color: #2e0927;
    color: #fff;
    position: relative;
    z-index: 10;
}

.section.iot-focus {
    padding-bottom: 0px;
    padding-top: 40px;
}

.container {
    .white-col-boxes {
        .col {
            background-color: #fff;
            margin: 5px;
        }
    }
    .grey-col-boxes {
        .col {
            border: 4px solid #f7f7f7;
            margin: 5px;
        }
    }
    .icons-inline {
        display: table;
        margin: 0 auto;
        z-index: 100;
        position: relative;

        ul {
            width: 100%;
            list-style: none;
        }
        li {
            list-style: none;
            display:inline-block;
            vertical-align: top;
            margin: 10px;
            width: 120px;
            height: 170px;
            overflow: none;
        }
        @include media-breakpoint-between(xs, sm) {
            zoom: 75%;
            ul {
                margin-left: -20px;
            }
        }
        @include media-breakpoint-up(md) {
            padding: 20px;
        }
    }
    .icons-inline.wide {
        li {
            width: 200px;
            @include media-breakpoint-between(xs, sm) {
                width: 150px;
            }
        }
    }
    .icons-inline.medium {
        li {
            width: 180px;
            @include media-breakpoint-between(xs, sm) {
                width: 150px;
            }
        }
    }
    .iot-services-icons {
        svg {
            height: 78px !important;
        }
        h5 {
            font-size: 16px;
        }
        .icon {
            color: #fff;
            text-align: center;
            padding-bottom: 20px;

            svg {
                height: 100px;
                fill: currentColor;
            }
        }
        .icon.dark {
            color: #000 !important;

            svg {
                height: 100px;
                fill: currentColor;
            }
        }
    }

}

.iot-platform-logos {

    margin-bottom: 20px;
    display: block;
    float: left;
    width: 100%; /* For 5 logos */
    /* Adjust margin and padding to taste */
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0;
    margin-right: 0;

    a {
        //display: block;
        display:inline-block;
        height: 100%;
        width: 200px;
        //float: left;
        position: relative; /* Needed for absolute positioning of images inside */

        border: 1px solid #ddd;
        min-height: 80px;
        padding: 5px;

    }

    svg {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}

.footer-contact.submit-button {
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}
.copyright {
    padding-top: 20px;
    font-size: 10px;
}
.kaleidoscope-integrations {
    display: inline-block;
    height: 200px;
    max-width: 700px;
    width: 100%;
    position: relative;
    // border: 1px solid #ddd;
    margin-top: 40px;
    margin-bottom: 20px;

    svg {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
    }
}
