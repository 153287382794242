@import url('https://fonts.googleapis.com/css?family=Questrial');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('https://fonts.googleapis.com/css?family=Tajawal');

// Core variables and mixins
@import "variables";
@import "mixins";

// Reset and dependencies
@import "normalize";
@import "print";

// Core CSS
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "hamburgers";

// Components
@import "sitenav";
@import "slidenav";
@import "sitefooter";
@import "section";
@import "hero";
@import "content";
@import "footerdark";
@import "utilities";
@import "timeline";

// Css Libraries
@import "animate";
